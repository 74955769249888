function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"manual-adjust-basic-fee"},[_c('div',{staticClass:"flex flex-row justify-between items-center"},[_c('Card',{attrs:{"title":"Fitur baru dengan upload bulky!","description":"Sekarang Anda bisa upload bulky dalam 1 file excel. Download templatenya sekarang!","primaryText":"Download Template","secondText":"Upload Bulky"},on:{"action":_vm.downloadTemplate,"secondaryAction":function () { return _vm.$router.push({
            name: 'UploadBulkyManualAdjustBasicFee',
          }); }}},[_c('IllustrationCreateFaq',{staticClass:"mr-6"})],1),_c('Button',{staticClass:"text-xs font-semibold py-2 px-4",attrs:{"customPadding":true,"buttonStyle":"outline"}},[_vm._v(" Export to CSV ")])],1),_c('div',{staticClass:"flex flex-row items-center gap-3 w-full"},[_c('div',{staticClass:"flex flex-col gap-2 w-2/5"},[_c('label',{staticClass:"text-sm font-semibold text-bgMatterhorn",attrs:{"for":"search"}},[_vm._v("Cari")]),_c('TextInput',{attrs:{"type":"search","placeholder":"Cari ID transaksi, nama file, kurir"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.search),callback:function ($$v) {_vm.$set(_vm.parameters, "search", $$v)},expression:"parameters.search"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-1/3"},[_c('label',{staticClass:"text-sm font-semibold text-bgMatterhorn",attrs:{"for":"search"}},[_vm._v("Tanggal")]),_c('DateTimePickerV2',{attrs:{"type":"range-1-button","usePresetRange":true,"presetRangeValue":_vm.datepickerPresets,"maxDate":new Date()},on:{"input":function () {
            _vm.fetchList(true)
          }},model:{value:(_vm.parameters.date),callback:function ($$v) {_vm.$set(_vm.parameters, "date", $$v)},expression:"parameters.date"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-3/12"},[_c('label',{staticClass:"text-sm font-semibold text-bgMatterhorn",attrs:{"for":"search"}},[_vm._v("Filter Tipe")]),_c('DropdownSelect',{attrs:{"options":_vm.typeOptions},on:{"input":_vm.onTypeFilter},model:{value:(_vm.parameters.type),callback:function ($$v) {_vm.$set(_vm.parameters, "type", $$v)},expression:"parameters.type"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-3/12"},[_c('label',{staticClass:"text-sm font-semibold text-bgMatterhorn",attrs:{"for":"search"}},[_vm._v("Tipe Upload")]),_c('DropdownSelect',{attrs:{"options":_vm.uploadTypeOptions},on:{"input":_vm.onUploadTypeFilter},model:{value:(_vm.parameters.uploadType),callback:function ($$v) {_vm.$set(_vm.parameters, "uploadType", $$v)},expression:"parameters.uploadType"}})],1)]),_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.tableData,"isLoading":_vm.controller.isLoading,"sort":_vm.parameters.sort},on:{"sort":function (val) {
        _vm.parameters.sort = val
        _vm.fetchList(true)
      }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"flex justify-center"},[_c('Loading')],1)]},proxy:true},{key:"4",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('div',{staticClass:"text-sm text-secondaryText"},[(data.data.value.length)?[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])]:[_vm._v("-")]],2)]}},{key:"5",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('div',{staticClass:"flex flex-col text-sm text-gray-4"},[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])])]}},{key:"8",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm text-gray-4 word-break"},[_vm._v(_vm._s(data.data.value))])]}},{key:"9",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm text-gray-4 word-break"},[_vm._v(_vm._s(data.data.value))])]}},{key:"10",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm",class:data.data.value.charAt(0) === '-' ? 'text-red-1' : 'text-green-2'},[_vm._v(_vm._s(data.data.value))])]}},{key:"11",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('Badge',{staticClass:"w-full px-4 py-1 text-center hdpi-laptop:w-3/4",attrs:{"data-testid":"global-component-data-table-v1__row__badge","type":_vm.formatedStatus(data.data.value).color,"customPadding":true}},[_vm._v(" "+_vm._s(_vm.formatedStatus(data.data.value).text)+" ")])]}},{key:"13",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('div',{staticClass:"text-sm text-secondaryText"},[(data.data.value.length)?[_c('div',[_vm._v(_vm._s(data.data.value[0]))]),_c('div',[_vm._v(_vm._s(data.data.value[1]))])]:[_vm._v("-")]],2)]}},{key:"14",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('button',{staticClass:"flex flex-row gap-2 items-center text-sm font-semibold text-bgPrimary",on:{"click":function () { return _vm.onHandleImageViewer(true, data.data.value); }}},[_c('EyeIcon'),_c('span',[_vm._v("Lihat Bukti")])],1)]}},{key:"15",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-sm text-gray-4 line-clamp-2"},[_vm._v(_vm._s(data.data.value))]),(data.data.value !== '-')?_c('span',{staticClass:"text-2xs text-bgPrimary cursor-pointer",on:{"click":function () { return _vm.handleModalNotes(true, data.data.value); }}},[_vm._v("Lihat Detail")]):_vm._e()])]}}])}),_c('PaginationNav',{attrs:{"enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem,"page":_vm.parameters.page,"lang":"id"},on:{"input":function () {
        _vm.fetchList()
      },"onChangeOption":function (val) {
        _vm.parameters.perPage = val
        _vm.fetchList(true)
      }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}),_c('h2',{staticClass:"font-bold text-gray-4"},[_vm._v("History")]),_c('DataTableV2',{attrs:{"headers":_vm.historyHeaders,"dataItems":_vm.historyTableData,"isLoading":_vm.controller.isLoadingHistoryManualAdjustBasicFee},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"flex justify-center"},[_c('Loading')],1)]},proxy:true},{key:"0",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"font-medium text-sm text-gray-4"},[_vm._v(_vm._s(data.data.value))])]}},{key:"1",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(data.data.value))])]}},{key:"2",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(data.data.value))])]}},{key:"3",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(data.data.value))])]}},{key:"4",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('span',{staticClass:"text-sm text-secondaryText"},[_vm._v(_vm._s(data.data.value))])]}},{key:"5",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-sm text-secondaryText line-clamp-1"},[_vm._v(_vm._s(data.data.value))]),(data.data.value !== '-')?_c('span',{staticClass:"text-2xs text-bgPrimary cursor-pointer",on:{"click":function () { return _vm.handleModalNotes(true, data.data.value); }}},[_vm._v("Lihat Detail")]):_vm._e()])]}},{key:"6",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [_c('Badge',{staticClass:"w-full px-4 py-1 text-center text-secondaryText hdpi-laptop:w-3/4",attrs:{"data-testid":"global-component-data-table-v1__row__badge","type":_vm.formatedStatus(data.data.value).color,"customPadding":true}},[_vm._v(" "+_vm._s(_vm.formatedStatus(data.data.value).text)+" ")])]}},{key:"7",fn:function(ref){
      var rest = objectWithoutProperties( ref, [] );
      var data = rest;
return [(data.data.value.split(';')[1])?_c('router-link',{staticClass:"flex gap-2 cursor-pointer",attrs:{"to":{
          name: 'ManualAdjustBasicFeeDetail',
          params: {
            historyId: data.data.value.split(';')[0],
          },
        }}},[_c('EyeIcon'),_c('span',{staticClass:"text-sm text-bgPrimary font-semibold"},[_vm._v("Lihat")])],1):_vm._e()]}}])}),_c('PaginationNav',{attrs:{"perPage":_vm.historyParameters.perPage,"totalItem":_vm.controller.historyManualAdjustBasicFeePaginationData.totalItem,"page":_vm.historyParameters.page,"lang":"id"},on:{"input":function () {
        _vm.fetchHistoryList()
      }},model:{value:(_vm.historyParameters.page),callback:function ($$v) {_vm.$set(_vm.historyParameters, "page", $$v)},expression:"historyParameters.page"}}),_c('ModalNotes',{attrs:{"visible":_vm.modalNotes.visible,"notes":_vm.modalNotes.notes},on:{"close":function () { return _vm.handleModalNotes(false, ''); }}}),_c('ImageViewer',{attrs:{"visible":_vm.imageViewerVisible,"data":_vm.imageUrls},on:{"close":function () { return _vm.onHandleImageViewer(false, []); }}}),(_vm.controller.isLoadingDownloadTemplate)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }